import 'reflect-metadata';

import { promises as fs } from 'fs';
import dynamic from 'next/dynamic';
import path from 'path';
import { Component } from 'react';

// import HomeComponent from '../components/home/home.component';
const HomeComponent = dynamic(() => import('../components/home/home.component'));

export async function getStaticProps({ params }) {
    const propsFile = path.join(process.cwd(), `/ssr-data/routes/build/data/root.json`);
    const data = await fs.readFile('/home/user/sites/ps2pdf/ps2pdf-web/ssr-data/routes/build/data/xconvert.com/root.json');
    const jObj = JSON.parse(data.toString());
    console.log(jObj);
    // Pass post data to the page via props
    return { props: jObj };
}

export default class IndexPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <HomeComponent {...this.props}></HomeComponent>;
    }
}